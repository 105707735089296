<script setup lang="ts"></script>

<template>
  <div>
    <div
      class="bg-main-2 flex flex-col gap-[10px] mobile:grid mobile:grid-cols-2"
    >
      <div
        class="bg-main-1 mobile:h-[82vh] rounded-[15px] p-[15px] mobile:rounded-[10px] flex flex-col gap-[15px]"
      >
        <img class="max-h-[333px]" src="../../assets/samsaIcon.svg" alt="" />
        <div class="flex flex-col gap-[7px]">
          <label class="font-medium" for="name">Название</label>
          <input
            value="qwerty"
            class="py-[11px] pl-2 border border-main-4 rounded-[10px] w-full"
            id="name"
            type="text"
          />
        </div>
        <div class="flex flex-col mobile:flex-row gap-[10px]">
          <div class="w-full flex flex-col gap-[15px]">
            <div class="w-full flex flex-col gap-[7px]">
              <label class="font-medium" for="adres">Адрес</label>
              <input
                value="qwerty"
                class="py-[11px] pl-2 border border-main-4 rounded-[10px] w-full"
                id="adres"
                type="text"
              />
            </div>
            <div class="w-full flex flex-col gap-[7px]">
              <label class="font-medium" for="twoGis">Ссылка 2ГИС</label>
              <input
                value="qwerty"
                class="py-[11px] pl-2 border border-main-4 rounded-[10px] w-full"
                id="twoGis"
                type="text"
              />
            </div>
            <div class="w-full flex flex-col gap-[7px]">
              <label class="font-medium" for="contacts">Контакты</label>
              <input
                value="qwerty"
                class="py-[11px] pl-2 border border-main-4 rounded-[10px] w-full"
                id="contacts"
                type="text"
              />
            </div>
          </div>
          <div class="w-full flex flex-col gap-[15px]">
            <div class="w-full flex flex-col gap-[7px]">
              <label class="font-medium" for="contacts">Контакты</label>
              <input
                value="qwerty"
                class="py-[11px] pl-2 border border-main-4 rounded-[10px] w-full"
                id="contacts"
                type="text"
              />
            </div>
            <div class="w-full flex flex-col gap-[7px]">
              <label class="font-medium" for="city">Город</label>
              <input
                value="qwerty"
                class="py-[11px] pl-2 border border-main-4 rounded-[10px] w-full"
                id="city"
                type="text"
              />
            </div>
            <div class="w-full flex flex-col gap-[7px]">
              <label class="font-medium" for="instagram"
                >Instagram аккаунт</label
              >
              <input
                value="qwerty"
                class="py-[11px] pl-2 border border-main-4 rounded-[10px] w-full"
                id="instagram"
                type="text"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="bg-main-1 p-[15px] mobile:h-[82vh] rounded-[15px] mobile:rounded-[10px] mb-[82px] mobile:mb-0"
      >
        <div class="font-bold mb-[20px]">График работы</div>
        <div class="overflow-x-auto hideScroll">
          <div class="scheduleBlueGrid">
            <div>День недели</div>
            <div>Время</div>
            <div>Выходной</div>
          </div>
          <div class="scheduleGrid" v-for="i in 7">
            <div>Понедельник</div>
            <div class="flex items-center gap-[20px]">
              <div>c</div>
              <input
                class="border rounded-[10px] py-2 w-20 border-main-4"
                type="text"
              />
              <div>до</div>
              <input
                class="border rounded-[10px] py-2 w-20 border-main-4"
                type="text"
              />
            </div>
            <input type="checkbox" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
