import { IPriceListData, IPriceListKey } from "@/Type/PriceList";

export const validatePriceList = (foodData: IPriceListKey & IPriceListData) => {
  const isPrice = foodData.price.toString().length > 0 &&  +foodData.price >= 0;
  const isNameKz = foodData.nameKaz.length > 0;
  const isNameRu = foodData.nameRus.length > 0;
  const isArticle = foodData.article.length > 0;
  const isKitchens = foodData.kitchens.length > 0;
  const isTimeCooking = foodData.preparationTimeMinutes > 0;
  // const isNameKitchen = foodData.nameKitchen.length > 0;
  const isDescriptionKz = foodData.descriptionKaz.length > 0;
  const isDescriptionRu = foodData.descriptionRus.length > 0;
  const isFoodCategoryId = foodData.foodCategoryId > 0;

  return (
    isPrice &&
    isNameKz &&
    isNameRu &&
    isArticle &&
    isKitchens &&
    isTimeCooking &&
    isKitchens &&
    isDescriptionKz &&
    isDescriptionRu &&
    isFoodCategoryId
  );
};

export const isErrorKzOrRu = (
  foodData: IPriceListKey & IPriceListData
): string => {
  const isNameKz = foodData.nameKaz.length > 0;
  const isNameRu = foodData.nameRus.length > 0;
  const isDescriptionKz = foodData.descriptionKaz.length > 0;
  const isDescriptionRu = foodData.descriptionRus.length > 0;

  if (!isNameKz || !isDescriptionKz) return "kz";
  else if (!isNameRu || !isDescriptionRu) return "ru";
  else return "kz";
};

export const defaultPriceFoodData = (
  foodId: number | null,
  restaurantId: number,
): IPriceListData => ({
  id: foodId,
  discountPercentage: "",
  cashbackPercentage: "",
  descriptionKaz: "",
  descriptionRus: "",
  nameKaz: "",
  nameRus: "",
  price: "",
  preparationTimeMinutes: "",
  article: "",
  foodCategoryId: "",
  restaurantId,
  kitchens: [],
  activeLang: "",
  imageUrl: "",
});
// nameKitchen: "",
