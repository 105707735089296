import { ICategory } from "@/Type/PriceList";
import { IReportDate } from "@/Type/Reports";
import { createStore } from "vuex";
import { dateFormat } from "../helpers/reports";
import { IRestaurantInfo } from "@/Type/Restaurant";

interface IState {
  CHOOSE_CATEGORY_NAME: string;
  CATEGORY_ID: number | null;
  ALL_CATEGORY: ICategory[];
  SEARCH_EMPLOYEE_INPUT: string;
  SEARCH_FOOD_INPUT: string;
  USER_TOKEN: string | null;
  RESTAURANT_ID: string | null;
  REPORT_DATE: IReportDate;
  RESTAURANT_INFO: IRestaurantInfo | { [key: string]: number | string };
}

export default createStore({
  state() {
    return {
      CATEGORY_ID: -1,
      CHOOSE_CATEGORY_NAME: "",
      ALL_CATEGORY: [],
      SEARCH_EMPLOYEE_INPUT: "",
      SEARCH_FOOD_INPUT: "",
      USER_TOKEN: localStorage.getItem("token"),
      RESTAURANT_ID: localStorage.getItem("restaurantId"),
      REPORT_DATE: { from: dateFormat(new Date()), to: dateFormat(new Date()) },
      RESTAURANT_INFO: {},
    };
  },
  getters: {
    getCurrentCategoryName(state: IState): string {
      return state.CHOOSE_CATEGORY_NAME;
    },
    getCurrentCategoryID(state: IState): number | null {
      return state.CATEGORY_ID;
    },
    getAllCategory(state: IState): ICategory[] {
      if (!state.ALL_CATEGORY.length) return state.ALL_CATEGORY;
      const data = [
        ...state.ALL_CATEGORY.slice(1).sort((a, b) =>
          a.nameRus.localeCompare(b.nameRus)
        ),
      ];

      return [state.ALL_CATEGORY[0], ...data];
    },
    getSearchEmployees(state: IState): string {
      return state.SEARCH_EMPLOYEE_INPUT;
    },
    getSearchFood(state: IState): string {
      return state.SEARCH_FOOD_INPUT;
    },
    getToken(state: IState): string | null {
      return state.USER_TOKEN;
    },
    getRestaurantID(state: IState): string | null {
      return state.RESTAURANT_ID;
    },
    getReportDate(state: IState): IReportDate {
      return state.REPORT_DATE;
    },
    getRestaurantInfo(
      state: IState
    ): IRestaurantInfo | { [key: string]: number | string } {
      return state.RESTAURANT_INFO;
    },
  },
  mutations: {
    setCurrentCategoryID(state: IState, currentCategoryId: number) {
      state.SEARCH_FOOD_INPUT = "";
      state.CATEGORY_ID = currentCategoryId;
    },
    setCurrentCategoryName(state: IState, chooseCategoryName: string) {
      state.CHOOSE_CATEGORY_NAME = chooseCategoryName;
    },
    setAllCategory(state: IState, allCategory: ICategory[]) {
      const data: ICategory = {
        id: -1,
        nameKaz: "Барлығы",
        nameRus: "Все",
        priority: -1,
        restaurantId: +(state.RESTAURANT_ID ?? 1),
      };
      state.CHOOSE_CATEGORY_NAME = data.nameRus;
      state.ALL_CATEGORY = [data, ...allCategory];
    },
    setSearchEmployees(state: IState, searchEmployees: string) {
      state.SEARCH_EMPLOYEE_INPUT = searchEmployees;
    },
    setSearchFood(state: IState, searchFoods: string) {
      state.CATEGORY_ID = -1;
      state.SEARCH_FOOD_INPUT = searchFoods;
    },
    setToken(state: IState, token: string) {
      state.USER_TOKEN = token;
      localStorage.setItem("token", token);
    },
    setRestaurantID(state: IState, restaurantId: number) {
      state.RESTAURANT_ID = restaurantId.toString();
      localStorage.setItem("restaurantId", restaurantId.toString());
    },
    setReportDate(state: IState, dateInfo: { time: string; name: string }) {
      state.REPORT_DATE[dateInfo.name] = dateInfo.time;
    },
    setRestaurantInfo(state: IState, restaurantInfo: IRestaurantInfo) {
      state.RESTAURANT_INFO = restaurantInfo;
    },
  },
  actions: {},
  modules: {},
});
