import store from "@/store";
import axios from "axios";

export const getShiftOpenTime = async (
  dateFrom: string,
  restaurantId: string
) => {
  return new Promise(async (res, rej) => {
    try {
      const response = await axios.get(
        `https://109.233.108.126:9090/api/restaurant/${restaurantId}/report/shift-opened?date=${dateFrom}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.USER_TOKEN}`,
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        return res(response.data);
      }
    } catch (error) {
      return rej(error);
    }
  });
};

export const getShiftCloseTime = async (
  dateTo: string,
  restaurantId: string
) => {
  return new Promise(async (res, rej) => {
    try {
      const response = await axios.get(
        `https://109.233.108.126:9090/api/restaurant/${restaurantId}/report/shift-closed?date=${dateTo}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.USER_TOKEN}`,
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        return res(response.data);
      }
    } catch (error) {
      return rej(error);
    }
  });
};
