import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Employees from "../pages/Employees.vue";
import PriceList from "../pages/PriceList.vue";
import Reports from "../pages/Reports.vue";
import BranchSettings from "../pages/BranchSettings.vue";
import BranchInfo from "../components/BranchSettings/BranchInfo.vue";
import TablesAndHalls from "../components/BranchSettings/TablesAndHalls.vue";
import RevenueTaxes from "../components/Reports/RevenueTaxes.vue";
import RevenueEmployee from "../components/Reports/RevenueEmployee.vue";
import DangerousOperations from "../components/Reports/DangerousOperations.vue";
import SoldFoods from "../components/Reports/SoldFoods.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "employees",
    component: Employees,
  },
  {
    path: "/priceList",
    name: "priceList",
    component: PriceList,
  },
  {
    path: "/reports",
    name: "reports",
    component: Reports,
    children: [
      {
        path: "revenueTaxes",
        name: "revenueTaxes",
        component: RevenueTaxes,
      },
      {
        path: "revenueEmployee",
        name: "revenueEmployee",
        component: RevenueEmployee,
      },
      {
        path: "dangerousOperations",
        name: "dangerousOperations",
        component: DangerousOperations,
      },
      {
        path: "soldFoods",
        name: "soldFoods",
        component: SoldFoods,
      },
    ],
  },
  {
    path: "/branchSettings",
    name: "branchSettings",
    component: BranchSettings,
    children: [
      {
        path: "",
        name: "branchInfo",
        component: BranchInfo,
      },
      {
        path: "tablesAndHalls",
        name: "tablesAndHalls",
        component: TablesAndHalls,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
