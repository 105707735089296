import { IEmployee, IRoles } from "@/Type/Employees";

export const defaultEmployeeData = (): IEmployee => ({
  id: 0,
  chatId: 0,
  phoneNumber: "",
  firstName: "",
  lastName: "",
  login: "",
  password: "",
  roles: "",
  code: "",
  incomePercentage: "",
  isVisibleCode: false,
});

export const allRolesData = (): IRoles[] => [
  {
    name: "ROLE_ADMIN",
    nameRus: "Админ",
  },
  {
    name: "ROLE_MANAGER",
    nameRus: "Управляющий",
  },
  {
    name: "ROLE_ADMINISTRATOR",
    nameRus: "Администратор",
  },
  {
    name: "ROLE_CASHIER",
    nameRus: "Кассир",
  },
  {
    name: "ROLE_SENIOR_WAITER",
    nameRus: "Старший официант",
  },
  {
    name: "ROLE_WAITER",
    nameRus: "Официант",
  },
];
