import { ports } from "@/ports/ports";

export const httpServerUrl = "https://109.233.108.126:" + ports.test;

export const httpUrl = "https://109.233.108.126:9090";

//todo ALL
export const getAll = "/getAll";
export const setSaveAll = "/save";
export const setCreateAll = "/create";
export const setDeleteAll = "/delete";
export const setLogin = "/api/auth/login";

//todo PriceList
export const allCategoryPropertie = "/api/manager/categories";

//todo Employees
export const allEmployeePropertie = "/api/manager/employee";
export const getAllRolesEmployee = "/getAllRoles";
export const getSearchEmployees = "/search?name=";

//todo Foods
export const allFoodsPropertie = "/api/manager/foods";
export const getAllByCategoriesFood = "/getByCategories";
export const getAllKitchensFood = "/getAllKitchens";

//todo categories
export const getAllCategories = "/api/food-category/get-all";
export const addCategory = "/api/food-category/create";
export const deleteCategory = "/api/food-category/";
export const updateCategories = "/api/food-category/update";
