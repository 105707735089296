<script setup lang="ts"></script>

<template>
  <div class="px-[15px]">
    <div
      class="bg-main-3 w-[245px] text-main-1 font-medium mobile:text-xl py-[6px] px-1 rounded-[10px] text-center cursor-pointer transition-all duration-75 active:bg-[#2E84AE] ease-in"
    >
      Добавить сотрудника
    </div>
  </div>
</template>
